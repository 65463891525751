import React from 'react'

function importAll(r) {
  let images = {}
  r.keys().forEach((item, index) => {
    images[item.replace('./', '')] = r(item)
  })
  return images
}

const community = importAll(
  require.context(
    '../../images/sponsors/community',
    false,
    /\.(png|jpe?g|svg)$/
  )
)

const communityList = [
  {
    image: community['mlh-logo-color.png'],
    name: 'MLH',
    url: 'https://mlh.io/',
  },
  {
    image: community['uoLogo.png'],
    name: 'University of Ottawa Faculty of Engineering',
    url: 'https://engineering.uottawa.ca/',
  },
  {
    image: community['bloomberg.png'],
    name: 'Bloomberg',
    url: 'https://bloomberg.com',
  },
  {
    image: community['github.png'],
    name: 'GitHub',
    url: 'https://github.com',
  },
]

class CommunityList extends React.Component {
  render() {
    return (
      <div className="community">
        {communityList.map((item, key) => {
          return (
            <a key={key} href={item.url} alt={item.name}>
              <img src={item.image} alt="" />
            </a>
          )
        })}
      </div>
    )
  }
}
export default CommunityList
