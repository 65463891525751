export const FAQList = [
  {
    question: 'What is uOttaHack?',
    answer: {
      __html:
        "uOttaHack is an MLH hackathon hosted by University of Ottawa students! It's a sprint-like event where students from all across Canada will come to turn their ideas into real projects. However, they only have 24 hours to accomplish their goal. Hackers will receive mentorship, learn about new job opportunities, get some cool swag, and have fun along the way!",
    },
  },
  {
    question: 'Who can participate?',
    answer: {
      __html:
        "Any students, at any skill level, from any field of study are welcome! The only thing you need is an interest in building something amazing and the motivation to learn! <br /><br /> <b>Are you a recent grad?</b>  No worries - as long as you've graduated in the last 12 months, you're welcome to apply and attend!",
    },
  },
  {
    question: 'Where and when is the event?',
    answer: {
      __html:
        "The hackathon will be hosted on the beautiful campus of the University of Ottawa and will take place February 9th - 10th, 2019. Don’t worry about getting lost! We'll have plenty of signage and volunteers around campus to guide the way.",
    },
  },
  {
    question: 'How much will it cost me?',
    answer: {
      __html:
        ' <b>Nothing at all!</b> uOttaHack is completely free, including all meals during the hackathon. We will also provide as much hype as you can possibly imagine. Did we also mention that each hacker will be getting an amazing swag bag?',
    },
  },
  {
    question: "What if I've never been to a hackathon before?",
    answer: {
      __html:
        "There's a first time for everything right? Hackathons are all about learning new things and having an amazing experience. We'll have plenty of workshops and mentors during the event to help you out! Last year, <b>30%</b> of our participants were first time hackers!",
    },
  },
  {
    question: 'Can I start working on a project before the event?',
    answer: {
      __html:
        "All projects must be started from scratch at the event, but you're free to start brainstorming ideas beforehand!",
    },
  },
  {
    question: 'Can I participate as part of a team?',
    answer: {
      __html:
        '<b>Yes!</b>   Hacking can be done solo, or in a team of up to four participants.',
    },
  },
  {
    question: "What if I'm not from Ottawa?",
    answer: {
      __html:
        "We'd love to have you in the world's coldest capital! We'll be sending buses to areas where lots of hackers are coming from (Montreal, Waterloo, Toronto, etc.). As well as there will be travel reimbursements. However, travel reimbursements are granted on a case by case basis and we cannot guarantee them for all participants.  More info will be available closer to the hackathon!",
    },
  },
  {
    question: 'Can high school students come?',
    answer: {
      __html:
        '<b>Yes!</b> We highly encourage younger high school students to apply and come get creative! Minors attending will be asked to have waivers signed by their parents/guardians.',
    },
  },
  {
    question: 'What should I bring?',
    answer: {
      __html:
        'Bring whatever helps you hack! Some important items to bring include a valid form of student ID, laptop and charger, deodorant (arguably mandatory), a change of clothes, and maybe a sleeping bag. If you have specific hardware you’d like to work with, feel free to bring it. MLH will also be providing a hardware lab with a wide range of items.',
    },
  },
  {
    question: 'Sounds great, but what do I win?',
    answer: {
      __html:
        "We started uOttaHack to empower students in creating innovative, fun, and awesome projects, without the stress of a regular semester's weekend. The biggest reward you will get is the friends you make that last a lifetime and an experience you won't forget. <br> <br> <b> But, with that said - </b> you'll have the chance to demo to our stellar panel of judges and the top teams will be rewarded with some sweet prizes! There will also be smaller prizes for specific categories and potentially some given out by our sponsors!",
    },
  },
  {
    question: 'What is Major League Hacking?',
    answer: {
      __html:
        'Major League Hacking is the <a href="https://mlh.io"> official student hackathon league</a> in North America & Europe. They work with over 200 member events and empower over 70,000 students every year.',
    },
  },
  {
    question: 'Is there a code of conduct?',
    answer: {
      __html:
        'Yes there is. The organizers care deeply about everyone\'s experience and ensuring they get to hack in a comfortable and safe environment. You can find it <a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf"/>here</a>.',
    },
  },
]
