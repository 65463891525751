import React from 'react'
import SponsorList from './SponsorList'
import CommunityList from './CommunityList'
import './sponsors.scss'

class Sponsors extends React.Component {
  render() {
    return (
      <div>
        <div className="sponsors" id="sponsors">
          <h2>Sponsors</h2>
          <p>
            Interested in sponsoring our hackathon? Send us an email at{' '}
            <a href="mailto:sponsorship@uottahack.ca">
              sponsorship@uottahack.ca
            </a>
          </p>
          <SponsorList />
          <h2>Community Partners</h2>
          <CommunityList />
        </div>
      </div>
    )
  }
}
export default Sponsors
