import React from 'react'
import { Link } from 'gatsby'
import HelmetComponent from '../components/HelmetComponent'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Hero from '../components/Hero'
import Showcase from '../components/Showcase'
import CountDownSection from '../components/CountdownSection'
import HackerStories from '../components/HackerStories/HackerStories'
import Sponsors from '../components/Sponsors/Sponsors'
import Location from '../components/Location'
import FAQ from '../components/FAQ/FAQ'
import LastCallToAction from '../components/LastCallToAction'
import Footer from '../components/Footer'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSidebarOpen: false,
    }

    this.toggleOpen = this.toggleOpen.bind(this)
  }

  toggleOpen(event) {
    this.setState(prevState => {
      return {
        isSidebarOpen: !prevState.isSidebarOpen,
      }
    })
    if (event.target.nodeName === 'A') {
      window.location.replace(event.target.getAttribute('href'))
    }
  }

  render() {
    const logo = (
      <Link className="nav-title" to="/">
        {'uOttaHack'}
        <sup className="nav-superscript">2019</sup>
      </Link>
    )

    const links = (
      <div className="nav-links">
        <AnchorLink className="section-link" href="#about">
          ABOUT
        </AnchorLink>
        <Link className="section-link" to="/schedule">
          SCHEDULE
        </Link>
        <AnchorLink className="section-link" href="#sponsors">
          SPONSORS
        </AnchorLink>
        <AnchorLink className="section-link" href="#stories">
          STORIES
        </AnchorLink>
        <AnchorLink className="section-link" href="#location">
          LOCATION
        </AnchorLink>
        <AnchorLink className="section-link" href="#faq">
          FAQ
        </AnchorLink>
      </div>
    )

    return (
      <div className={'index-container ' + this.state.isSidebarOpen}>
        <HelmetComponent />
        <Header logo={logo} links={links} />
        <Sidebar
          logo={logo}
          links={links}
          isOpen={this.state.isSidebarOpen}
          onClick={this.toggleOpen}
        />
        <Hero />
        <Showcase />
        <CountDownSection />
        <HackerStories />
        <Sponsors />
        <Location />
        <FAQ />
        <LastCallToAction
          sectionTitle="Still got questions?"
          subtitle="Send us an email at"
          email="hi@uottahack.ca"
        />
        <Footer />
      </div>
    )
  }
}

export default Index
