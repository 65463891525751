import React from 'react'

import './style.scss'
import heart from '../images/hero-heart.svg'

export default Hero => (
  <div className="hero-section">
    <div className="call-to-action-container">
      <p className="main-title">
        Join hundreds of hackers to build and explore exciting possibilities
      </p>
      <p className="date-time">
        University of Ottawa <span>· February 9-10, 2019</span>
      </p>
      <p className="sign-up">
        <strong>
          Thank you so much for attending uOttaHack 2019! This event wouldn't
          have been possible without all the hackers, volunteers, and sponsors.
          We can't wait to see you all again at uOttaHack 3!
        </strong>
      </p>
    </div>
    <div className="heart-container">
      <img className="heart" src={heart} alt="" />
    </div>
  </div>
)
