import React from 'react'
import { FAQList } from './FAQList.js'

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion'

class Questions extends React.Component {
  render() {
    return (
      <Accordion>
        {FAQList.map(function(question, key) {
          return (
            <AccordionItem key={key}>
              <AccordionItemTitle>
                <h3>{question.question}</h3>
              </AccordionItemTitle>
              <AccordionItemBody>
                <p dangerouslySetInnerHTML={question.answer} />
              </AccordionItemBody>
            </AccordionItem>
          )
        })}
      </Accordion>
    )
  }
}
export default Questions
