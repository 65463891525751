import React, { Component } from 'react'
import Countdown from './Countdown.js'

import './style.scss'

class CountdownSection extends Component {
  render() {
    return (
      <div className="countdown-wrapper">
        <div className="countdown-title">
          <div className="title">Hacking starts in</div>
        </div>
        <div className="countdown-time">
          <Countdown date={`2019-02-09T12:00:00`} />
        </div>
      </div>
    )
  }
}

export default CountdownSection
