import React from 'react'

import './style.scss'
import showcase from '../images/showcase.jpg'

export default Showcase => (
  <div className="showcase-wrapper" id="about">
    <div className="text-container">
      <h2>The biggest hackathon in the capital is back!</h2>
      <p>
        uOttaHack is back for the 2019 hackathon season, so get ready for
        another exciting weekend full of teamwork, innovation, and creativity!
        On February 9th and 10th, we'll challenge over 500 hackers from across
        North America to build their most ambitious projects in just 24 hours.
        <br />
        <br />
        While you're not busy working on your project, you'll have the chance to
        attend workshops, play with awesome tech, talk to recruiters, and make
        some new friends. We believe that everybody should be empowered to
        create and bring their ideas to life. We encourage artists,
        entrepreneurs, and innovators from all faculties and skill levels to
        attend. We can't wait to see you there!
      </p>
      <h3>Last year we had...</h3>
      <div className="stats-container">
        <div className="stats-box">
          <p className="stats-title">400+</p>
          <p>Hackers</p>
        </div>
        <div className="stats-box">
          <p className="stats-title">$2000</p>
          <p>In prizes awarded</p>
        </div>
        <div className="stats-box">
          <p className="stats-title">39</p>
          <p>Schools represented</p>
        </div>
      </div>
    </div>
    <div className="picture-container">
      <img src={showcase} alt="" />
      <div className="style-bar" />
    </div>
  </div>
)
