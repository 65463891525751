import React, { Component } from 'react'

import './faq.scss'
import Questions from './Questions'

class FAQ extends Component {
  render() {
    return (
      <div className="section-wrapper" id="faq">
        <div className="section-titles">
          <h2>Frequently Asked Questions</h2>
        </div>
        <Questions />
      </div>
    )
  }
}
export default FAQ
