import React from 'react'

import './style.scss'

class LastCallToAction extends React.Component {
  render() {
    return (
      <div className="last-cta-wrapper">
        <div className="section-titles">
          <h2>{this.props.sectionTitle}</h2>
          <p>
            {this.props.subtitle}{' '}
            <a href={`mailto:` + this.props.email}>{this.props.email}</a>
          </p>
        </div>
      </div>
    )
  }
}

export default LastCallToAction
