import React from 'react'

import './style.scss'

const map = (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22403.045830058632!2d-75.6989616604492!3d45.42182600000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce0553301ba91f%3A0x88fbccabd3b90bc4!2sLearning+Crossroads+%7C+Carrefour+des+apprentissages!5e0!3m2!1sen!2sca!4v1543137445039"
    title="crossRoads"
    width="100%"
    height="450"
    frameBorder="0"
    allowFullScreen
  />
)

export default Location => (
  <div className="location-container" id="location">
    <h2>Location</h2>
    {map}
  </div>
)
