import React from 'react'
import Stories from './Stories'

import '../style.scss'

class HackerStories extends React.Component {
  render() {
    return (
      <div className="section-wrapper" id="stories">
        <div className="section-titles">
          <h2>Stories from our hackers</h2>
        </div>
        <div className="hacker-stories">
          <Stories />
        </div>
      </div>
    )
  }
}
export default HackerStories
