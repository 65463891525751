import React from 'react'

import './style.scss'
import oval from '../images/site-oval.svg'
import facebook from '../images/Facebook.svg'
import instagram from '../images/Instagram.svg'
import twitter from '../images/Twitter.svg'

export default Footer => (
  <div className="footer">
    <img className="oval-left" src={oval} alt="" />
    <hr />
    <div className="footer-links">
      <div className="social-links">
        <a href="https://facebook.com/uottahack">
          <img src={facebook} alt="" />
        </a>
        <a href="https://instagram.com/uottahack">
          <img src={instagram} alt="" />
        </a>
        <a href="https://twitter.com/uottahack">
          <img src={twitter} alt="" />
        </a>
      </div>
      <div className="info-links">
        <h1>
          Made by the uOttaHack team{' '}
          <span role="img" aria-label="toast">
            🍞
          </span>
        </h1>
      </div>
    </div>
    <img className="oval-right" src={oval} alt="" />
  </div>
)
