import React from 'react'

import './sponsors.scss'

function importAll(r) {
  let images = {}
  r.keys().forEach((item, index) => {
    images[item.replace('./', '')] = r(item)
  })
  return images
}

const silver = importAll(
  require.context('../../images/sponsors/silver', false, /\.(png|jpe?g|svg)$/)
)

const bronze = importAll(
  require.context('../../images/sponsors/bronze', false, /\.(png|jpe?g|svg)$/)
)

const inKind = importAll(
  require.context('../../images/sponsors/in-kind', false, /\.(png|jpe?g|svg)$/)
)

const silverList = [
  {
    image: silver['solace.png'],
    name: 'Solace',
    url: 'https://solace.com/',
  },
]

const bronzeList = [
  {
    image: bronze['gcp.png'],
    name: 'Google Cloud Platform',
    url: 'https://cloud.google.com/',
  },
  {
    image: bronze['morganstanley.png'],
    name: 'Morgan Stanley',
    url: 'https://www.morganstanley.com/',
  },
  {
    image: bronze['surveymonkey.png'],
    name: 'Survey Monkey',
    url: 'https://www.surveymonkey.com/',
  },
  {
    image: bronze['cse.svg'],
    name: 'CSE',
    url: 'https://www.cse-cst.gc.ca',
  },
  {
    image: bronze['phreesia.png'],
    name: 'Phressia',
    url: 'https://www.phreesia.com/',
  },
  {
    image: bronze['kinaxis.png'],
    name: 'Kinaxis',
    url: 'https://www.kinaxis.com/',
  },
  {
    image: bronze['ewa.png'],
    name: 'Intertek EWA-Canada',
    url: 'http://www.ewa-canada.com/',
  },
  {
    image: bronze['scalar.png'],
    name: 'Scalar',
    url: 'https://www.scalar.ca/',
  },
]

const inKindList = [
  {
    image: inKind['google.png'],
    name: 'Google',
    url: 'https://careers.google.com/students/',
  },
  {
    image: inKind['ea.png'],
    name: 'EA',
    url: 'https://www.ea.com/',
  },
  {
    image: inKind['lspark.png'],
    name: 'L-SPARK',
    url: 'https://www.l-spark.com/',
  },
  {
    image: inKind['sparklingice.png'],
    name: 'Sparkling Ice',
    url: 'https://www.sparklingice.ca/',
  },
  {
    image: inKind['spiria.png'],
    name: 'Spiria',
    url: 'https://www.spiria.com/en/',
  },
  {
    image: inKind['balsamiq.png'],
    name: 'Balasmiq',
    url: 'https://balsamiq.com/',
  },
  {
    image: inKind['kickinghorse.svg'],
    name: 'Kicking Horse',
    url: 'https://www.kickinghorsecoffee.com',
  },
  {
    image: inKind['raon.png'],
    name: 'Raon',
    url: 'https://www.raonkitchen.com/',
  },
  {
    image: inKind['wakeups.png'],
    name: 'Wakeups',
    url: 'https://wake-ups.com/',
  },
  {
    image: inKind['stickermule.png'],
    name: 'Sticker Mule',
    url: 'http://hackp.ac/mlh-stickermule-hackathons',
  },
  {
    image: inKind['sketch.png'],
    name: 'Sketch',
    url: 'https://sketchapp.com/',
  },
  {
    image: inKind['jumpstart.png'],
    name: 'Jumpstart',
    url: 'https://jumpstart.me/r/uOttaHack',
  },
]

class SponsorList extends React.Component {
  render() {
    return (
      <div className="sponsor-group">
        <div className="silver-sponsor">
          {silverList.map((item, key) => {
            return (
              <a key={key} href={item.url} alt={item.name}>
                <img src={item.image} alt="" />
              </a>
            )
          })}
        </div>
        <div className="bronze-sponsor">
          {bronzeList.map((item, key) => {
            return (
              <a key={key} href={item.url} alt={item.name}>
                <img src={item.image} alt="" />
              </a>
            )
          })}
        </div>
        <div className="in-kind-sponsor">
          {inKindList.map((item, key) => {
            return (
              <a key={key} href={item.url} alt={item.name}>
                <img src={item.image} alt="" />
              </a>
            )
          })}
        </div>
      </div>
    )
  }
}
export default SponsorList
