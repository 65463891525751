import React from 'react'
import Menu from './Menu'

import './Sidebar.scss'

class Sidebar extends React.Component {
  render() {
    return (
      <>
        <Menu onClick={this.props.onClick} isOpen={this.props.isOpen} />
        <div
          className={'sidebar ' + this.props.isOpen}
          onClick={this.props.onClick}>
          {this.props.logo}
          {this.props.links}
        </div>
      </>
    )
  }
}

export default Sidebar
