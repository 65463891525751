import React from 'react'

import './style.scss'
import mlhBadge from '../images/mlh-badge-2019.svg'

const Header = props => (
  <div>
    <div className="nav-bar">
      {props.logo}
      {props.links}
    </div>
    <a href="https://mlh.io/">
      <img src={mlhBadge} alt="" className="mlh-banner" />
    </a>
  </div>
)

export default Header
