import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

var settings = {
  className: 'center',
  centerMode: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  speed: 750,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: '60px',
  swipeToSlide: true,
  initialSlide: 4,
  dots: true,
  responsive: [
    {
      breakpoint: 1750,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0px',
      },
    },
  ],
}

function importAll(r) {
  let images = {}
  r.keys().forEach((item, index) => {
    images[item.replace('./', '')] = r(item)
  })
  return images
}

const images = importAll(
  require.context('../../images/hackers', false, /\.(png|jpe?g|svg)$/)
)

/*const HackerList = [
  {
    image: images['nick.jpg'],
    name: 'Nick Wu',
    study: 'Computer Engineering, 4th year',
    school: 'University of British Columbia',
    testimonial:
      '"Had a lot of fun meeting cool people, experimenting with frontend technologies, and winning a prize for a challenge!"',
  },
  {
    image: images['abhay.jpeg'],
    name: 'Abhay Mahajan',
    study: 'Electrical Engineering, 4th year',
    school: 'University of Ottawa',
    testimonial:
      "\"'sudo apt-get install hack' doesn't actually get you a hack that you can submit. In all seriousness - I realized I really enjoy the mentorship side of the hackathon and helped me decide that project management is right for me. Next year I'm definitely applying for a mentor position.\"",
  },
  {
    image: images['edward.jpg'],
    name: 'Edward Yang',
    study: 'Mechatronics Engineering, 2nd year',
    school: 'University of Waterloo',
    testimonial:
      '"It was my first hackathon. I got to meet cool people and in only a few short days I got to create something that I was very proud of."',
  },
  {
    image: images['xunxun.jpg'],
    name: 'Xunxun Shi',
    study: 'Biotechnology, 5th year',
    school: 'University of Ottawa',
    testimonial:
      "As a student who isn't enrolled in a tech major, uottaHack marked a great turning point for me. My experience not only exposed me to tech such as machine learning and augmented reality, but also challenged my creative thinking and team building skills. I ended up loving what I learned from the hackathon enough to pursue a career in machine learning. I am now a data scientist intern at TELUS, and I wouldn't be here if I hadn't clicked on the sign-up button.",
  },
]*/

class Stories extends React.Component {
  render() {
    return (
      <Slider {...settings}>
        <div className="story-container">
          <div className="story-image-container">
            <img src={images['nick.jpg']} alt="" />
          </div>
          <div className="story-text-container">
            <h3>Nick Wu</h3>
            <h4>Computer Engineering, 4th year</h4>
            <h5>University of British Columbia</h5>
            <p>
              "Had a lot of fun meeting cool people, experimenting with frontend
              technologies, and winning a prize for a challenge!"
            </p>
          </div>
        </div>

        <div className="story-container">
          <div className="story-image-container">
            <img src={images['abhay.jpeg']} alt="" />
          </div>
          <div className="story-text-container">
            <h3>Abhay Mahajan</h3>
            <h4>Electrical Engineering, 4th year</h4>
            <h5>University of Ottawa</h5>
            <p>
              "'sudo apt-get install hack' doesn't actually get you a hack that
              you can submit. In all seriousness - I realized I really enjoy the
              mentorship side of the hackathon and helped me decide that project
              management is right for me. Next year I'm definitely applying for
              a mentor position."
            </p>
          </div>
        </div>

        <div className="story-container">
          <div className="story-image-container">
            <img src={images['edward.jpg']} alt="" />
          </div>
          <div className="story-text-container">
            <h3>Edward Yang</h3>
            <h4>Mechatronics Engineering, 2nd year</h4>
            <h5>University of Waterloo</h5>
            <p>
              "It was my first hackathon. I got to meet cool people and in only
              a few short days I got to create something that I was very proud
              of."
            </p>
          </div>
        </div>

        <div className="story-container">
          <div className="story-image-container">
            <img src={images['xunxun.jpg']} alt="" />
          </div>
          <div className="story-text-container">
            <h3>Xunxun Shi</h3>
            <h4>Biotechnology, 5th year</h4>
            <h5>University of Ottawa</h5>
            <p>
              "As a student who isn't enrolled in a tech major, uottaHack marked
              a great turning point for me. My experience not only exposed me to
              tech such as machine learning and augmented reality, but also
              challenged my creative thinking and team building skills. I ended
              up loving what I learned from the hackathon enough to pursue a
              career in machine learning. I am now a data scientist intern at
              TELUS, and I wouldn't be here if I hadn't clicked on the sign-up
              button."
            </p>
          </div>
        </div>

        {/*HackerList.map(function(story, key) {
          return (
        <div key={key} className="story-container">
          <div className="story-image-container">
            <img src={story.image} alt="" />
          </div>
          <div className="story-text-container">
            <h3>{story.name}</h3>
            <h4>{story.study}</h4>
            <h5>{story.school}</h5>
            <p>{story.testimonial}</p>
          </div>
        </div>
      )
    })*/}
      </Slider>
    )
  }
}

export default Stories
